import {
  FieldValues,
  useController,
  Control,
  Controller,
  Path,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Grid, Stack, Box, TextField, Typography } from '@mui/material'
import { EntityPhoto } from '@/components'
import { PatchPhotos, ControlledFieldProps, ApiErrorData } from '@/types'
import {
  capitalizeFirstLetter,
  getFieldErrorMessage,
  hasFieldError,
} from '@/utils'
import { ChangeEvent } from 'react'

export type ControlledPhotosUploadProps<T extends FieldValues> = {
  name: Path<T>
  control: Control<T>
  isReadonly?: boolean
  eventId?: number
  label?: string
  apiErrorData?: ApiErrorData
  apiFieldName?: string
  isFieldStateErrorShown?: boolean
  errorMessagePrefix?: string
} & ControlledFieldProps<T>

export const ControlledPhotosUpload = <T extends FieldValues>({
  name,
  control,
  apiErrorData,
  label,
  isFieldStateErrorShown = true,
  apiFieldName,
  errorMessagePrefix = 'errors:validation',
}: ControlledPhotosUploadProps<T>): JSX.Element | null => {
  const { t } = useTranslation()

  const {
    field: { onChange, value },
  } = useController<T>({
    name,
    control,
  })

  const photos = value as PatchPhotos

  const onChoosePhotos = (e: ChangeEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement

    if (target.files) {
      const chosenPhotos = Array.from(target.files).map((photo) => ({
        file: photo,
        link: URL.createObjectURL(photo),
      }))

      onChange({
        ...value,
        chosenToUpload: [...value.chosenToUpload, ...chosenPhotos],
      })
    }
  }

  const onDeleteChosenPhoto = (index: number) => {
    if (photos && photos.chosenToUpload) {
      photos.chosenToUpload.splice(index, 1)
      onChange({
        ...value,
        chosenToUpload: [...photos.chosenToUpload],
      })
    }
  }

  const onDeleteUploadedPhoto = (id: number) => {
    if (photos && photos.chosenToUpload) {
      onChange({ ...value, stale: [...value.stale, id] })
    }
  }

  return (
    <Stack>
      <Typography>{label}</Typography>

      <Box width="33%">
        <Controller
          name={name}
          control={control}
          render={({ fieldState: { error } }) => (
            <TextField
              id={name}
              onChange={onChoosePhotos}
              value=""
              type="file"
              error={
                !!error || hasFieldError(apiFieldName ?? name, apiErrorData)
              }
              helperText={
                isFieldStateErrorShown && error?.message
                  ? t(
                      errorMessagePrefix
                        ? `${errorMessagePrefix}.${error.message}`
                        : error.message
                    )
                  : capitalizeFirstLetter(
                      getFieldErrorMessage(apiFieldName ?? name, apiErrorData)
                    )
              }
              inputProps={{
                multiple: true,
              }}
            />
          )}
        />
      </Box>

      <Grid container={true} spacing={2} mt={4}>
        {photos?.chosenToUpload.map((photo, index) => (
          <EntityPhoto
            photo={photo}
            onDelete={() => onDeleteChosenPhoto(index)}
            key={index}
          />
        ))}
        {photos?.uploadedToApi.map(
          (photo, index) =>
            !photos.stale.includes(photo.id) && (
              <EntityPhoto
                photo={photo}
                onDelete={() => onDeleteUploadedPhoto(photo.id)}
                key={`${photo.id}_${index}`}
              />
            )
        )}
      </Grid>
    </Stack>
  )
}
