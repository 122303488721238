import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, Button, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import {
  LocationAutocomplete,
  AutocompleteOption,
  ControlledTextField,
  ResearchersControlledAutocomplete,
} from '@/components/inputs'
import { ProtocolsAutocomplete } from '@/components/inputs/ProtocolsAutocomplete'
import { DrawResearchAreaGraphics } from '@/components/forms/ResearchAreaForm/DrawResearchAreaGraphics'
import { yupResolver } from '@hookform/resolvers/yup'
import { projectAreaSchema } from './validationSchema'
import { getGeoName } from '@/libs/googleMaps'
import { capitalizeFirstLetter } from '@/utils'
import {
  FormComponentProps,
  ProjectProtocol,
  ResearchArea,
  ResearchAreaGraphic,
  RoundType,
} from '@/types'
import { getFirstGeometryPoint } from '@/utils/researchAreaGraphics'

export type AreaAddress = {
  locationName: string
  locationLat?: string
  locationLong?: string
}

type AreaRound = {
  round: RoundType
  researchers: number
}

export type ResearchAreaFormData = {
  id?: number
  areaAddress: AreaAddress | null
  clientAreaNumber?: string
  morningResearchers?: number | null
  dayResearchers?: number | null
  eveningResearchers?: number | null
  nightResearchers?: number | null
  protocols: AutocompleteOption[]
  researchers: AutocompleteOption[]
  graphics: ResearchAreaGraphic[]
  notes?: string
}

export type ResearchAreaFormProps = {
  projectId: number
  onDeleteArea: (id?: number) => void
  isNewData?: boolean
  setIsNextStepDisabled?: Dispatch<SetStateAction<boolean>>
  isPlanningCreated?: boolean
  researchAreasData: ResearchArea[]
  projectProtocols?: ProjectProtocol[]
} & FormComponentProps<ResearchAreaFormData>

export const ResearchAreaForm = ({
  projectId,
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
  onDeleteArea,
  isNewData = true,
  setIsNextStepDisabled,
  isPlanningCreated = false,
  researchAreasData,
  projectProtocols,
}: ResearchAreaFormProps): JSX.Element => {
  const { t } = useTranslation('forms')

  const [areaRounds, setAreaRounds] = useState<AreaRound[]>([])

  const roundResearchersMaxNumber = areaRounds.reduce(
    (acc, r) => ({
      ...acc,
      [r.round]: r.researchers,
    }),
    {}
  )

  const schema = projectAreaSchema({
    researchersMaxNumber: roundResearchersMaxNumber,
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { isDirty, errors, isValid },
    reset,
  } = useForm<ResearchAreaFormData>({
    mode: 'onChange',
    defaultValues: {
      areaAddress: null,
      clientAreaNumber: '',
      morningResearchers: null,
      dayResearchers: null,
      eveningResearchers: null,
      nightResearchers: null,
      protocols: [],
      researchers: [],
      graphics: [],
      notes: '',
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  const protocols = watch('protocols')

  useEffect(() => {
    if (defaultValues && protocols && projectProtocols) {
      const rounds: AreaRound[] = []

      protocols.forEach(({ value: areaSelectedProtocol }) =>
        projectProtocols.forEach((projectProtocol) => {
          if (projectProtocol.id === Number(areaSelectedProtocol)) {
            projectProtocol.rounds.forEach(
              ({ round_type, required_number_of_researchers }) => {
                //TODO fix that because it is possible to have multiple rounds with the same round type
                const index = rounds.findIndex((i) => i.round === round_type)

                if (index === -1) {
                  rounds.push({
                    round: round_type,
                    researchers: required_number_of_researchers,
                  })

                  if (getValues(`${round_type}Researchers`) === null) {
                    setValue(
                      `${round_type}Researchers`,
                      defaultValues[`${round_type}Researchers`] ?? 1
                    )
                  }
                } else if (
                  rounds[index].researchers < required_number_of_researchers
                ) {
                  rounds[index].researchers = required_number_of_researchers
                }
              }
            )
          }
        })
      )

      rounds.sort((a: AreaRound, b: AreaRound): number => {
        const order: { [key: string]: number } = {
          morning: 0,
          day: 1,
          evening: 2,
          night: 3,
        }

        return order[a.round] - order[b.round]
      })

      setAreaRounds(rounds)

      Object.keys(RoundType).forEach((key) => {
        const round = RoundType[key as keyof typeof RoundType]

        if (
          !rounds.some((item) => item.round === round) &&
          getValues(`${round}Researchers`) !== null
        ) {
          setValue(`${round}Researchers`, null, {
            shouldDirty: true,
          })
        }
      })
    }
  }, [defaultValues, protocols, projectProtocols])

  useEffect(() => {
    if (isDirty) {
      setIsNextStepDisabled?.(true)
    } else {
      setIsNextStepDisabled?.(false)
    }
  }, [isDirty])

  const updateLocation = async (lat: string, long: string) => {
    try {
      const { addressVariants, street_address } = await getGeoName({
        lat: +lat,
        lng: +long,
      })

      if (addressVariants) {
        setValue(
          'areaAddress',
          {
            locationName:
              street_address?.formatted_address ??
              addressVariants[0].formatted_address,
            locationLat: lat,
            locationLong: long,
          } as AreaAddress,
          { shouldDirty: true }
        )
      }
    } catch (error) {
      console.error('Error fetching location name:', error)
    }
  }

  const handleSaveGraphics = (graphics: ResearchAreaGraphic[]) => {
    setValue('graphics', graphics, {
      shouldDirty: true,
      shouldValidate: true,
    })

    if (!graphics.length) return

    const firstGeometryPoint = getFirstGeometryPoint(graphics[0])

    if (firstGeometryPoint) {
      const lat = firstGeometryPoint[1].toString()
      const long = firstGeometryPoint[0].toString()

      if (
        lat !== getValues('areaAddress.locationLat') ||
        long !== getValues('areaAddress.locationLong')
      ) {
        updateLocation(lat, long)
      }
    }
  }

  const handleReset = () =>
    reset({
      ...defaultValues,
    })

  const researchersMaxNumber = areaRounds.reduce((acc, cv) => {
    const researchersNumber = Number(watch(`${cv.round}Researchers`))

    return researchersNumber > acc ? researchersNumber : acc
  }, 0)

  const selectedResearchers = watch('researchers')

  return (
    <form
      onSubmit={handleSubmit(async (data) =>
        onSubmit({ ...data, id: defaultValues?.id })
      )}
      data-testid="research-area-form"
    >
      <Stack spacing={7.5} sx={{ backgroundColor: 'transparent' }}>
        <ProtocolsAutocomplete
          name="protocols"
          control={control}
          protocols={projectProtocols}
          disabled={!isNewData && isPlanningCreated}
        />
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          <Box>
            <DrawResearchAreaGraphics
              projectId={projectId}
              onSave={handleSaveGraphics}
              otherResearchAreas={researchAreasData.filter(
                (ra) => ra.id !== defaultValues?.id
              )}
              graphics={watch('graphics')}
              areaAddress={watch('areaAddress')}
              areaId={defaultValues?.id}
            />
          </Box>
          {!isValid && errors.graphics?.message && (
            <Typography variant="body2" color="error.main" ml={1}>
              {errors.graphics.message}
            </Typography>
          )}
        </Stack>
        <LocationAutocomplete name="areaAddress" control={control} />
        {areaRounds.length ? (
          <Stack
            direction="row"
            spacing={5}
            marginLeft={1}
            marginTop={4}
            justifyContent="flex-start"
          >
            <Box mt={2.5}>
              <Typography>{t('placeholders.numberOfResearchers')}</Typography>
            </Box>
            {areaRounds.map(({ round, researchers }) => (
              <Box key={round}>
                <ControlledTextField
                  key={`round_${round}`}
                  name={`${round}Researchers`}
                  control={control}
                  type="number"
                  inputProps={{ min: 1 }}
                  placeholder={t('placeholders.roundNumberOfResearchers', {
                    round: capitalizeFirstLetter(round),
                    researchers,
                  })}
                  apiFieldName={`${round}Researchers`}
                  apiErrorData={apiErrorData}
                  errorMessagePrefix=""
                />
              </Box>
            ))}
          </Stack>
        ) : null}

        <Box>
          <ResearchersControlledAutocomplete
            name="researchers"
            control={control}
            latitude={watch('areaAddress.locationLat')}
            longitude={watch('areaAddress.locationLong')}
            projectId={projectId}
          />
          {selectedResearchers.length > researchersMaxNumber && (
            <Typography variant="body2" color="error.main" ml={1} mt={1}>
              {t('projectArea.notification.numberOfResearchers')}
            </Typography>
          )}
        </Box>

        <Box width={200}>
          <ControlledTextField
            name="clientAreaNumber"
            control={control}
            placeholder={t('placeholders.clientAreaNumber')}
          />
        </Box>

        <ControlledTextField
          name="notes"
          control={control}
          placeholder={t('placeholders.notes')}
          multiline={true}
          inputProps={{
            style: {
              padding: 2,
            },
          }}
        />

        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          spacing={5}
        >
          {isDirty && (
            <Stack direction="row" alignItems="center" spacing={5} mb={3}>
              <Button
                variant="textThin"
                color="secondary"
                onClick={handleReset}
                data-testid="cancel-changes-button"
              >
                {t('projectArea.cancelChanges')}
              </Button>
              <LoadingButton
                variant="contained"
                color="secondary"
                type="submit"
                loading={isLoading}
                disableElevation={true}
                disabled={!isDirty}
                data-testid="submit-button"
              >
                <span>
                  {t(
                    defaultValues?.id
                      ? 'projectArea.updateArea'
                      : 'projectArea.saveArea'
                  )}
                </span>
              </LoadingButton>
            </Stack>
          )}

          {!isNewData && !isPlanningCreated && (
            <Button
              variant="textThin"
              color="buttonGrey"
              onClick={() => onDeleteArea(defaultValues?.id)}
              data-testid="delete-area-button"
              sx={{ ml: 'auto' }}
            >
              {t('projectArea.deleteArea')}
            </Button>
          )}
        </Stack>
      </Stack>
    </form>
  )
}
