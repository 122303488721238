import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box } from '@mui/material'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledTextField,
  ControlledDateTimeField,
  ControlledSelect,
  ControlledPhotosUpload,
} from '@/components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { schema } from './validationSchema'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'
import { FormComponentProps } from '@/types'
import {
  AMPHIBIANS_COUNTING_METHOD_OPTIONS,
  AMPHIBIANS_GEDRAG_OPTIONS,
  AMPHIBIANS_SKIP_BEHAVIOUR,
  AMPHIBIANS_SPECIE_OPTIONS,
  OTHER_GEDRAG_OPTION_VALUE,
  OTHER_SPECIE_OPTION_VALUE,
} from '../fieldsOptions'

export const AmphibianFishForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      specie: '',
      other_specie: '',
      amount: '',
      counting_method: '',
      behaviour: '',
      other_behaviour: '',
      behaviour_notes: '',
      recording_number: '',
      observed_at: '',
      photos: undefined,
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  const watchedSpecie = methods.watch('specie')
  const countingMethod = methods.watch('counting_method')

  return (
    <FormProvider {...methods}>
      <Stack mt={4} width="100%" alignItems="stretch" spacing={4}>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <ControlledSelect
            control={methods.control}
            name="specie"
            label={t('observation-log.fieldLabels.specie')}
            options={AMPHIBIANS_SPECIE_OPTIONS}
            errorMessagePrefix=""
          />
          {watchedSpecie === OTHER_SPECIE_OPTION_VALUE && (
            <>
              <ControlledTextField
                control={methods.control}
                name="other_specie"
                placeholder={t('observation-log.fieldLabels.otherSpecie')}
                errorMessagePrefix=""
              />
            </>
          )}
          <ControlledTextField
            control={methods.control}
            name="amount"
            placeholder={t('observation-log.fieldLabels.amount')}
            errorMessagePrefix=""
          />
          <ControlledSelect
            control={methods.control}
            name="counting_method"
            label={t('observation-log.fieldLabels.countingMethod')}
            options={AMPHIBIANS_COUNTING_METHOD_OPTIONS}
            errorMessagePrefix=""
          />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          {!!countingMethod &&
            !AMPHIBIANS_SKIP_BEHAVIOUR.includes(countingMethod) && (
              <Box width="33%">
                <ControlledSelect
                  control={methods.control}
                  name="behaviour"
                  label={t('observation-log.fieldLabels.behaviour')}
                  options={AMPHIBIANS_GEDRAG_OPTIONS}
                  errorMessagePrefix=""
                />
              </Box>
            )}
          {methods.watch('behaviour') === OTHER_GEDRAG_OPTION_VALUE && (
            <Box width="33%">
              <ControlledTextField
                control={methods.control}
                name="other_behaviour"
                placeholder={t('observation-log.fieldLabels.otherBehaviour')}
                errorMessagePrefix=""
              />
            </Box>
          )}
        </Stack>
        {!!countingMethod &&
          !AMPHIBIANS_SKIP_BEHAVIOUR.includes(countingMethod) && (
            <ControlledTextField
              control={methods.control}
              name="behaviour_notes"
              placeholder={t('observation-log.fieldLabels.behaviourNotes')}
              multiline={true}
              rows={3}
              inputProps={{
                style: {
                  padding: 0,
                },
              }}
              errorMessagePrefix=""
            />
          )}
        <Box width="33%">
          <ControlledTextField
            control={methods.control}
            name="recording_number"
            placeholder={t('observation-log.fieldLabels.recordingNumber')}
            type="number"
            errorMessagePrefix=""
          />
        </Box>
        <ControlledDateTimeField
          name="observed_at"
          control={methods.control}
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />
        <ControlledPhotosUpload
          name="photos"
          control={methods.control}
          label={t('observation-log.fieldLabels.photos')}
          errorMessagePrefix=""
        />

        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}
