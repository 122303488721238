import { useTranslation } from 'react-i18next'
import { Box, Button, Grid } from '@mui/material'
import { ChosenFile, ModelPhoto } from '@/types'

export type EntityPhotoProps = {
  photo: ChosenFile | ModelPhoto
  onDelete?: () => void
}

export const EntityPhoto = ({
  photo,
  onDelete,
}: EntityPhotoProps): JSX.Element => {
  const { t } = useTranslation()

  return (
    <Grid item={true} md={3}>
      <Box position="relative">
        <a href={photo.link} target="_blank" rel="noreferrer">
          <img
            src={photo.link}
            style={{ width: '100%', height: 'auto', borderRadius: 4 }}
            alt=""
          />
        </a>
        {!!onDelete && (
          <Button
            onClick={onDelete}
            variant="contained"
            size="small"
            color="error"
            style={{ position: 'absolute', top: 5, right: 5 }}
          >
            {t('common:buttons.delete')}
          </Button>
        )}
      </Box>
    </Grid>
  )
}
