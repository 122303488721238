export enum UserGroups {
  CLIENT = 'client',
  MANAGER = 'manager',
  EMPLOYEE = 'employee',
}

export type UserGroup = `${UserGroups}`

export type User = {
  id: number
  groups: UserGroup[]
  email: string
  first_name: string | null
  last_name: string | null
  phone: string | null
  address_name: string | null
  address_long: string | null
  address_lat: string | null
  is_senior: boolean | null
  notes: string | null
}

export type ProjectStatus =
  | 'new'
  | 'in_planning'
  | 'plan'
  | 'planned'
  | 'fieldwork'
  | 'deskwork'
  | 'delivered'

export type Project = {
  id: number
  start_date: string | null
  end_date: string | null
  client_name: string
  client_id: number
  progress?: number
  events_count?: string
  protocol_events_count?: string
  project_number: string
  name: string
  description?: string | null
  status: ProjectStatus | null
  sharepoint_url?: string | null
  address?: string | null
  is_in_gis: boolean
  is_smp: boolean
  is_only_planning: boolean
  is_field_report_required: boolean
}

export type PostProject = Omit<
  Project,
  'id' | 'client_name' | 'progress' | 'events_count' | 'protocol_events_count'
>

export type PatchProject = Partial<
  Omit<
    Project,
    'client_name' | 'progress' | 'events_count' | 'protocol_events_count'
  >
>

export type ProjectName = Pick<Project, 'id' | 'name' | 'project_number'>

export type ProjectSpecieBasic = {
  id: number | null
  specie_id: number | string
  research_type_id?: number
}

export type ProjectSpecie = ProjectSpecieBasic & {
  specie: Specie
  research_type: ResearchType | null
  created_date?: string
}

export type ResearchAreaGraphicGeometryPolygon = {
  spatialReference: {
    wkid: number
  }
  rings: Array<Array<[number, number]>>
}

export type ResearchAreaGraphicGeometryLine = {
  spatialReference: {
    wkid: number
  }
  paths: Array<Array<[number, number]>>
}

export type ResearchAreaGraphicGeometryPoint = {
  spatialReference: {
    wkid: number
  }
  x: number
  y: number
}

export type ResearchAreaGraphicGeometry =
  | ResearchAreaGraphicGeometryPolygon
  | ResearchAreaGraphicGeometryLine
  | ResearchAreaGraphicGeometryPoint

export enum ResearchAreaGraphicType {
  AREA = 'area',
  CYCLING_ROUTE = 'cycling_route',
  WALKING_ROUTE = 'walking_route',
  STAND_PLACE = 'stand_place',
}

export type ResearchAreaGraphic = {
  id?: number
  round_type?: RoundType | null
  animal?: string
  geometry: ResearchAreaGraphicGeometry
  type: ResearchAreaGraphicType
}

export type ResearchArea = {
  id: number
  location_lat?: string
  location_long?: string
  location_name?: string | null
  client_area_number?: string | null
  required_number_of_researchers_for_morning?: number | null
  required_number_of_researchers_for_day?: number | null
  required_number_of_researchers_for_evening?: number | null
  required_number_of_researchers_for_night?: number | null
  protocol_events_total_duration?: number
  protocol_events_count?: number
  events_count?: number
  protocols: ProjectProtocol[]
  researchers: EventResearcher[]
  protocol_ids?: string[] | number[]
  researcher_ids?: string[] | number[]
  graphics: ResearchAreaGraphic[]
  notes?: string
  project?: Project
  report_sharepoint_url?: string
}

export type ResearchAreaMonitoring = Pick<
  ResearchArea,
  | 'id'
  | 'location_lat'
  | 'location_long'
  | 'location_name'
  | 'client_area_number'
  | 'protocols'
  | 'researchers'
  | 'project'
> & {
  has_events_with_reasons_of_uncertainty: string
}

export type Animal = {
  id: number
  name: string
}

export type Specie = {
  id: number
  name: string
  animal: Animal
  research_types?: ResearchType[]
  scientific_name: string
}

export type ChosenFile = {
  file: Blob
  link: string
}

export type ModelPhoto = {
  id: number
  link: string
}

export type PatchPhotos = {
  chosenToUpload: ChosenFile[]
  uploadedToApi: ModelPhoto[]
  stale: number[]
}

export enum ModelsWithPhoto {
  visitLogs = 'visit_logs',
  observationLogs = 'observation_logs',
  fieldReports = 'field_reports',
}

export type ResearchType = {
  id: number
  name: string
}

type Researcher = {
  id: number
  first_name: string
  last_name: string
  email: string
  phone: string
  address_name: string
  is_senior: boolean
  notes: string
}

export type EventResearcher = Researcher & {
  distance: number
  is_available: boolean
  allowed_number_of_research_areas: number | null
}

export type EventVisitLog = Pick<VisitLog, 'id' | 'status' | 'is_manual_status'>

export type ResearchEventStatus =
  | 'ready_for_plan'
  | 'proposal_sent'
  | 'waiting_for_approval'
  | 'proposal_approved'
  | 'planned'

export enum ProcessingStatuses {
  TODO = 'todo',
  IN_PROGRESS = 'in_progress',
  DONE = 'done',
}

export type ProcessingStatus = `${ProcessingStatuses}`

export type SunCalendar = {
  date: string
  sunset: string
  sunrise: string
}

export type FieldReport = {
  id: number
  research_visit_event_id: number
  field_impression: string
  round_impression: string
  photos: ModelPhoto[]
}

export type PatchFieldReport = Pick<
  FieldReport,
  'research_visit_event_id' | 'field_impression' | 'round_impression'
>

export type ResearchVisitEvent = {
  id: number
  start_datetime: string
  end_datetime: string
  researchers: EventResearcher[]
  protocol_id: number
  original_protocols?: number[] | null
  research_area_id: number
  is_generated: boolean
  research_visit_log: EventVisitLog | null
  round_type: RoundType
  round_number?: number | null
  plan_proposed_by: number | null
  status: ResearchEventStatus
  sun_calendar: SunCalendar[]
  processing_status: ProcessingStatus | null
  processing_comment: string | null
  visit_protocol_id?: number
  is_event_approved: boolean
}

export type PutResearchVisitEventManagerProcessing = Pick<
  ResearchVisitEvent,
  'processing_status' | 'processing_comment'
>

export type ResearchVisitEventWithProjectInfo = ResearchVisitEvent & {
  research_area: ResearchArea & { project: Project }
  field_report?: FieldReport | null
}

export type ManagerResearchVisitEvent = ResearchVisitEvent & {
  project_name: string
  project_number: string
  is_event_passed: string
  is_event_going: string
  research_area_id: number
  protocol_start_date: string
  protocol_end_date: string
  reason_of_uncertainty: UncertaintyReason
}

export type ManagerResearchVisitEventWithProjectInfo =
  ManagerResearchVisitEvent & ResearchVisitEventWithProjectInfo

export enum UncertaintyReasons {
  NOT_PLANNED = 'not_planned',
  RUN_OUT = 'run_out',
  INVALID_PLANNING = 'invalid_planning',
  NO_ROUND_IN_A_MONTH = 'no_round_in_a_month',
  RE_PLANNED = 're_planned',
  INVALID_VISIT_LOG = 'invalid_visit_log',
  VISIT_LOG_WITHOUT_REPORT = 'visit_log_without_report',
}

export type UncertaintyReason = `${UncertaintyReasons}`

export type PatchResearchVisitEvent = Pick<
  ResearchVisitEvent,
  'id' | 'start_datetime' | 'end_datetime'
> & {
  researcher_ids: string[]
}

export type PatchManagerResearchVisitEvent = Pick<
  PatchResearchVisitEvent,
  'researcher_ids'
>

export type PostResearchVisitEvent = Pick<
  ResearchVisitEvent,
  'start_datetime' | 'research_area_id' | 'protocol_id'
> & {
  researcher_ids: string[]
}

export type PlanningResearchArea = ResearchArea & {
  research_visits_events: ResearchVisitEvent[]
}

export type VisitLog = {
  id: number
  research_visit_event?: ResearchVisitEventWithProjectInfo
  photos: ModelPhoto[]
  researchers: User[] | null
  created_by: User | null
  edited_by: User | null
  object_id: string | null
  global_id: number | null
  precipitation: string | null
  precipitation_duration: string | null
  wind_direction: string | null
  wind_force: number | null
  cloud_cover: string | null
  start_temperature: number | null
  end_temperature: number | null
  start_datetime: string | null
  end_datetime: string | null
  comment: string | null
  field_impression: string | null
  is_left_earlier: boolean
  reason_of_leaving: string | null
  created_at: string | null
  updated_at: string | null
  longitude: string | null
  latitude: string | null
  status: VisitLogStatus
  is_manual_status: boolean
  status_update_reason: string | null
  invalid_variables: string[] | null
  extra_research_visit_event: number | null
  processing_status: ProcessingStatus | null
  processing_comment: string | null
  is_field_report_added: boolean
}

export type PatchVisitLog = {
  research_visit_event_id: number
} & Required<
  Pick<
    VisitLog,
    | 'precipitation'
    | 'precipitation_duration'
    | 'wind_force'
    | 'cloud_cover'
    | 'start_temperature'
    | 'end_temperature'
    | 'start_datetime'
    | 'end_datetime'
  >
> &
  Partial<Pick<VisitLog, 'comment' | 'is_left_earlier' | 'reason_of_leaving'>>

export type PostRequestExtraVisitEvent = Pick<VisitLog, 'status_update_reason'>

export type PutVisitLogManagerProcessing = Pick<
  VisitLog,
  'processing_status' | 'processing_comment' | 'is_field_report_added'
>

export enum VisitLogStatuses {
  VALID = 'valid',
  VALID_WITHOUT_FIELD_REPORT = 'valid_without_field_report',
  INVALID = 'invalid',
  APPROVED = 'approved',
  APPROVED_WITHOUT_FIELD_REPORT = 'approved_without_field_report',
  EXTRA_EVENT_REQUESTED = 'extra_event_requested',
}

export type VisitLogStatus = `${VisitLogStatuses}`

export type Planning = Project & {
  research_areas: PlanningResearchArea[]
}

export type Plannings = Planning[]

export type VisitLogs = VisitLog[]

export type ResearcherAvailability = {
  date: string
  available_for_morning_round: boolean
  available_for_day_round: boolean
  available_for_evening_round: boolean
}

export type ResearcherCommitment = {
  year: number
  week: number
  max_days_available: number
}

export type DataValidationError<T> = {
  value: T
  has_error: boolean
}

export type DataValidationEnvironmentalData = {
  start_temperature: DataValidationError<number | null>
  end_temperature: DataValidationError<number | null>
  cloud_cover: DataValidationError<string | null>
  wind_force: DataValidationError<number | null>
  wind_direction: DataValidationError<string | null>
  precipitation: DataValidationError<string | null>
  precipitation_duration: DataValidationError<string | null>
}

export type ObservationTypeCode =
  | 'bats'
  | 'bats_feeding_area'
  | 'bats_flight_path'
  | 'courtship_territory'
  | 'birds_breeding'
  | 'mammals'
  | 'amphibian_fish'
  | 'reptiles'
  | 'insects'

export type ObservationType = {
  id: number
  code: ObservationTypeCode
  name: string
}

export type ObservationLog = {
  id: number
  research_visit_event: ResearchVisitEventWithProjectInfo
  research_observation_log_type: ObservationType
  photos: ModelPhoto[] | null
  specie: string | null
  specie2: string | null
  specie3: string | null
  specie4: string | null
  other_specie: string | null
  amount: number | null
  amount2: number | null
  amount3: number | null
  amount4: number | null
  counting_method: string | null
  counting_method2: string | null
  counting_method3: string | null
  counting_method4: string | null
  behaviour: string | null
  other_behaviour: string | null
  behaviour_notes: string | null
  direction: string | null
  wind_direction: string | null
  is_sound_made: boolean
  recording_number: string | null
  amount_per_15_min: number | null
  is_place_found: boolean
  place_type: string | null
  place_type_notes: string | null
  first_fledgling_time: string | null
  photo: string | null
  notes: string | null
  address: string | null
  longitude: string | null
  latitude: string | null
  //geometry: {}
  object_id: string | null
  created_at: string | null
  updated_at: string | null
  observed_at: string | null
  processing_comment: string | null
  created_by: Researcher | null
  edited_by: Researcher | null
}

export type PatchObservationLog = {
  research_visit_event_id: number
  research_observation_log_type_id: number
} & Partial<
  Omit<ObservationLog, 'research_visit_event' | 'research_observation_log_type'>
>

export type PutObservationLogManagerProcessing = Pick<
  ObservationLog,
  'processing_comment'
>

export enum RoundType {
  MORNING = 'morning',
  DAY = 'day',
  EVENING = 'evening',
  NIGHT = 'night',
}

export type OriginProtocol = {
  id: number
  specie: Specie
  research_type: ResearchType
}

export type ProjectProtocolRound = {
  id: number
  round_type: RoundType
  number_of_visits: number
  required_number_of_researchers: number
  from_date: string | null
  to_date: string | null
  visit_duration: number
  from_time?: string | null
  to_time?: string | null
  touch_time?: string | null
  start_time_relative_to_sunset?: number | null
  end_time_relative_to_sunrise?: number | null
  period_between_visits_map?: Record<string, number>
  method_of_identification?: string | null
  from_wind_force?: number | null
  to_wind_force?: number | null
  from_temperature?: number | null
  to_temperature?: number | null
  max_precipitation?: string | null
  one_round_mandatory_in_a_month?: string | null
  original_protocols?: number[] | null
}

export type ProjectProtocol = {
  id: number
  project_id: number
  original_protocols: OriginProtocol[]
  is_customized: boolean
  rounds: ProjectProtocolRound[]
}

export type Client = {
  id: number
  name: string
}
