import * as yup from 'yup'
import { photosSchema } from '@/components/inputs/ControlledPhotosUpload/photosValidation'

export const schema = yup.object({
  specie: yup.string().required(),
  amount: yup.string().required(),
  counting_method: yup.string().required(),
  wind_direction: yup.string().required(),
  direction: yup.string().required(),
  amount_per_15_min: yup.string().required(),
  notes: yup.string().max(256),
  observed_at: yup.date().required(),
  photos: photosSchema,
})
