import { AxiosResponse } from 'axios'
import { useApiClient, getPhotoBaseEndpoint } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { ModelsWithPhoto } from '@/types'

type UseDeletePhotoParams = {
  modelKey: ModelsWithPhoto
}

type UseDeletePhotoPayload = {
  entityId: number
  photoId: number
}

export const useDeleteEntityPhoto = ({
  modelKey,
}: UseDeletePhotoParams): ((
  data: UseDeletePhotoPayload
) => Promise<AxiosResponse<void>>) => {
  const client = useApiClient()

  return ({ photoId, entityId }) =>
    client
      .delete(
        `${getPhotoBaseEndpoint(modelKey)}/${entityId}/photos/${photoId}/`
      )
      .then((response) => response)
      .catch(unwrapErrorResponse)
}
