import {
  ObservationType,
  PaginatedListResponse,
  SearchRequestParams,
  APIErrorResponse,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'

export const useObservationTypes = (
  { search, ...params }: SearchRequestParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<ObservationType>, APIErrorResponse> =>
  useQueryList({
    url: '/plannings/observation_types/',
    options: {
      enabled,
    },
    params: {
      ...params,
      ...(search ? { search } : {}),
    },
  })
