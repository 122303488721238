import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import {
  ResearchVisitEventWithProjectInfo,
  APIErrorResponse,
  ResearchArea,
} from '@/types'

export const usePostProjectEventsGenerateForArea = (
  projectId: number
): UseMutationResult<
  ResearchVisitEventWithProjectInfo,
  APIErrorResponse,
  Pick<ResearchArea, 'id'>
> => {
  const client = useApiClient()

  return useMutation((params) =>
    client
      .post(`plannings/${projectId}/events/generate_for_area/${params.id}/`)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
