import { useTranslation } from 'react-i18next'
import { Box, Stack, Typography, IconButton, Pagination } from '@mui/material'
import { X } from 'react-feather'
import { ObservationLogsTable, Observation } from '@/features/observationLogs'
import { Loader } from '@/components'
import {
  SearchTextField,
  SearchDateField,
  ResearchersMultiSelect,
  IsPlaceFoundSelect,
  SearchAutocomplete,
  BooleanSelect,
  ObservationTypesSelect,
} from '@/components/inputs'
import { EmptyListData } from '@/components/ui'
import {
  useModal,
  useFilters,
  useObservationLogs,
  UseObservationLogsSearchParams,
  useSpecies,
  useBehaviours,
} from '@/hooks'
import { theme } from '@/theme'
import { DEFAULT_LIST_LIMIT, DEFAULT_LIST_OFFSET } from '@/constants'
import { useEffect } from 'react'

export const ObservationLogsContainer = (): JSX.Element => {
  const { t } = useTranslation('observationLogs')
  const { open } = useModal()

  const defaultFiltersParams: UseObservationLogsSearchParams = {
    id: undefined,
    search: undefined,
    createdBy: undefined,
    isPlaceFound: undefined,
    behaviour: undefined,
    specie: undefined,
    observationType: undefined,
    hasProcessingComment: undefined,
    limit: DEFAULT_LIST_LIMIT,
    offset: DEFAULT_LIST_OFFSET,
  }

  const openObservationLog = (id: number) => {
    if (data && data.results) {
      const index = data.results.findIndex((row) => row.id === id)

      if (index !== -1) {
        open(<Observation observationLog={data.results[index]} />)
      }
    }
  }

  const {
    filterParams,
    handleFieldValueChange,
    handleResetFilters,
    initPagesCount,
    pagesCount,
    page,
    onPageChange,
    openDetail,
  } = useFilters<UseObservationLogsSearchParams>(defaultFiltersParams)

  const { data, isLoading } = useObservationLogs(filterParams)
  const species = useSpecies()
  const behaviours = useBehaviours()

  useEffect(() => {
    if (data && data.count) {
      initPagesCount(data?.count)

      const detailItemId = openDetail()

      if (detailItemId) {
        openObservationLog(detailItemId)
      }
    }
  }, [data])

  return (
    <Stack>
      <Stack
        direction={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        spacing={2}
      >
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="center"
          mb={4}
          spacing={4}
        >
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <SearchTextField
              searchValue={filterParams.id as string}
              onChange={(value) => handleFieldValueChange(value, 'id')}
              placeholder="ID"
              searchIcon={false}
              width="80px"
            />
            <SearchTextField
              searchValue={filterParams.search}
              onChange={(value) => handleFieldValueChange(value, 'search')}
              placeholder={t('searchPlaceholder')}
              width="460px"
            />
            <BooleanSelect
              label={t('forms:placeholders.hasProcessingComment')}
              value={filterParams.hasProcessingComment as boolean}
              onChange={(value) =>
                handleFieldValueChange(value, 'hasProcessingComment')
              }
            />
            <SearchDateField
              searchStartValue={filterParams.startDate as string}
              searchEndValue={filterParams.endDate as string}
              onChangeStartDate={(date) =>
                handleFieldValueChange(date, 'startDate')
              }
              onChangeEndDate={(date) =>
                handleFieldValueChange(date, 'endDate')
              }
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <ResearchersMultiSelect
              value={filterParams.createdBy as string}
              onChange={(value) => handleFieldValueChange(value, 'createdBy')}
              placeholder={t('placeholders.createdBy')}
            />
            <IsPlaceFoundSelect
              value={filterParams.isPlaceFound as boolean}
              onChange={(value) =>
                handleFieldValueChange(value, 'isPlaceFound')
              }
            />
            <SearchAutocomplete
              options={species}
              value={filterParams.specie as string}
              onChange={(value) => handleFieldValueChange(value, 'specie')}
              placeholder={t(`placeholders.specie`)}
              sort={true}
              fieldSx={{ width: 315 }}
            />
            <SearchAutocomplete
              options={behaviours}
              value={filterParams.behaviour as string}
              onChange={(value) => handleFieldValueChange(value, 'behaviour')}
              placeholder={t(`placeholders.behaviour`)}
              sort={true}
              fieldSx={{ width: 315 }}
            />
          </Stack>
          <Stack
            direction={{ xs: 'column', md: 'row' }}
            alignItems={{ xs: 'flex-start', md: 'center' }}
            justifyContent="flex-start"
            spacing={4}
            width="100%"
          >
            <ObservationTypesSelect
              value={filterParams.observationType as string}
              onChange={(value) =>
                handleFieldValueChange(value, 'observationType')
              }
              placeholder={t('placeholders.observationType')}
            />
          </Stack>
        </Stack>
        <Typography color="green.600">
          {t('amountWithCount', { count: data?.count ?? 0 })}
        </Typography>
        <IconButton
          onClick={handleResetFilters}
          title={t('buttons.resetFilters')}
        >
          <X size="16px" color={theme.palette.green['500']} />
        </IconButton>
      </Stack>

      {data?.count ? (
        <>
          <ObservationLogsTable
            rows={data.results}
            showObservationLogDetails={openObservationLog}
          />

          {pagesCount > 1 && (
            <Box alignSelf="center" mt={16}>
              <Pagination
                count={pagesCount}
                page={page}
                onChange={onPageChange}
              />
            </Box>
          )}
        </>
      ) : (
        <Box my={20}>
          {isLoading && <Loader />}

          {!isLoading && !data?.count && (
            <EmptyListData textKey="observationLogs:noObservationLogs" />
          )}
        </Box>
      )}
    </Stack>
  )
}
