import { AxiosResponse } from 'axios'
import { getPhotoBaseEndpoint } from '@/hooks/api/photos/getPhotoBaseEndpoint'
import { useApiClient } from '@/hooks'
import { unwrapErrorResponse } from '@/utils'
import { ChosenFile, ModelPhoto, ModelsWithPhoto } from '@/types'

type UsePostEntityPhotosParams = {
  modelKey: ModelsWithPhoto
}

type UsePostEntityPhotosData = {
  entityId: number
  photos: ChosenFile[]
}

export const usePostEntityPhotos = ({
  modelKey,
}: UsePostEntityPhotosParams): ((
  data: UsePostEntityPhotosData
) => Promise<PromiseSettledResult<AxiosResponse<ModelPhoto>>[]>) => {
  const client = useApiClient()

  return async (data) => {
    const requests = data.photos.map((photo) => {
      const formData = new FormData()

      formData.append('file', photo.file)

      return client.post<ModelPhoto>(
        `${getPhotoBaseEndpoint(modelKey)}/${data.entityId}/photos/`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
    })

    return Promise.allSettled(requests)
      .then((allResponses) => allResponses)
      .catch(unwrapErrorResponse)
  }
}
