import * as yup from 'yup'

export const photosSchema = yup.object({
  chosenToUpload: yup.array().of(
    yup.object().shape({
      file: yup.mixed(),
      link: yup.string(),
    })
  ),
  uploadedToApi: yup.array().of(
    yup.object().shape({
      id: yup.number(),
      link: yup.string(),
    })
  ),
  stale: yup.array().of(yup.number()),
})

type Photos = yup.InferType<typeof photosSchema>

export const validatePhotos = (value: Photos | undefined) => {
  const { chosenToUpload = [], uploadedToApi = [], stale = [] } = value || {}

  if (chosenToUpload.length === 0 && uploadedToApi.length === 0) {
    return false
  }

  const staleContainsAllOld =
    uploadedToApi.length > 0 &&
    uploadedToApi.every((photo) => stale.includes(photo.id))

  if (staleContainsAllOld && chosenToUpload.length === 0) {
    return false
  }

  return true
}
