import {
  ObservationLog,
  PaginatedListResponse,
  APIErrorResponse,
  SearchRequestParams,
} from '@/types'
import { UseQueryResult } from '@tanstack/react-query'
import { useQueryList } from './useQueryList'
import { QUERY_KEYS } from '@/constants'

export type UseObservationLogsSearchParams = SearchRequestParams & {
  id?: string
  date?: string
  createdBy?: string
  isPlaceFound?: boolean
  behaviour?: string
  specie?: string
  observationType?: string
  hasProcessingComment?: boolean
  startDate?: string
  endDate?: string
}

export const getUseObservationLogsKey = (): string[] =>
  QUERY_KEYS.OBSERVATION_LOGS.slice(0, 2) as string[]

export const useObservationLogs = (
  { ...params }: UseObservationLogsSearchParams,
  enabled = true
): UseQueryResult<PaginatedListResponse<ObservationLog>, APIErrorResponse> =>
  useQueryList({
    url: '/plannings/observation_logs/',
    options: {
      enabled,
    },
    params: {
      ...(!!params.id && { id: params.id }),
      ...(!!params.search && { search: params.search }),
      ...(!!params.createdBy && { created_by: params.createdBy }),
      ...(params.isPlaceFound !== undefined && {
        is_place_found: params.isPlaceFound,
      }),
      ...(!!params.behaviour && { behaviour: params.behaviour }),
      ...(!!params.specie && { specie: params.specie }),
      ...(!!params.observationType && {
        research_observation_log_type: params.observationType,
      }),
      ...(params.hasProcessingComment !== undefined && {
        has_processing_comment: params.hasProcessingComment,
      }),
      ...(!!params.startDate && { date_start: params.startDate }),
      ...(!!params.endDate && { date_end: params.endDate }),
      limit: params.limit,
      offset: params.offset,
    },
  })
