import { useTranslation } from 'react-i18next'
import { useSnackbar } from 'notistack'
import { usePostEntityPhotos, useDeleteEntityPhoto } from '@/hooks'
import { PatchPhotos, ModelsWithPhoto } from '@/types'

type UseUploadPhotosParams = {
  modelKey: ModelsWithPhoto
}

type UploadImagesParams = {
  photos: PatchPhotos
  entityId: number
}

export const useUploadPhotos = ({ modelKey }: UseUploadPhotosParams) => {
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const uploadPhotos = usePostEntityPhotos({ modelKey })

  const deletePhoto = useDeleteEntityPhoto({ modelKey })

  return async ({ photos, entityId }: UploadImagesParams) => {
    if (photos && photos.chosenToUpload) {
      const result = await uploadPhotos({
        entityId,
        photos: photos.chosenToUpload,
      })

      const failedIndexes = [] as string[]

      result.forEach((response, index) => {
        if (response.status === 'rejected' || response.value.status !== 200) {
          failedIndexes.push(`#${index + 1}`)

          return
        }
      })

      if (failedIndexes.length) {
        enqueueSnackbar(
          t('errors:api.uploadFailed', {
            indexes: failedIndexes.join(', '),
          }),
          {
            variant: 'error',
            persist: true,
          }
        )
      }
    }

    if (photos.stale) {
      for (const id of photos.stale) {
        await deletePhoto({
          entityId,
          photoId: id,
        })
      }
    }
  }
}
