import { useApiClient } from '@/hooks'
import { unwrapErrorResponse, unwrapResponse } from '@/utils/unwrapResponse'
import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { APIErrorResponse } from '@/types'

type UsePostProjectEventsGenerate = {
  projectId: number
}

export const usePostProjectEventsGenerate = ({
  projectId,
}: UsePostProjectEventsGenerate): UseMutationResult<
  unknown,
  APIErrorResponse,
  void
> => {
  const client = useApiClient()

  return useMutation(() =>
    client
      .post(`plannings/${projectId}/events/generate/`)
      .then(unwrapResponse)
      .catch(unwrapErrorResponse)
  )
}
