import { useForm, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Stack, Box, IconButton } from '@mui/material'
import { X } from 'react-feather'
import {
  ObservationLogFormData,
  ObservationLogFormActions,
} from '@/components/forms'
import {
  ControlledTextField,
  ControlledDateTimeField,
  ControlledSelect,
  ControlledPhotosUpload,
} from '@/components/inputs'
import { yupResolver } from '@hookform/resolvers/yup'
import { useApiErrorData } from '@/hooks/forms/useApiErrorData'
import { FormComponentProps } from '@/types'
import {
  BATS_BIRDS_COUNTING_METHOD_OPTIONS,
  BATS_SPECIE_OPTIONS,
} from '../fieldsOptions'
import { schema } from './validationSchema'
import { theme } from '@/theme'

export const BatsFeedingAreaForm = ({
  onSubmit,
  defaultValues,
  isLoading,
  apiErrorData,
}: FormComponentProps<ObservationLogFormData>): JSX.Element => {
  const { t } = useTranslation('observationLogs')

  const methods = useForm<ObservationLogFormData>({
    mode: 'onChange',
    defaultValues: {
      specie: '',
      specie2: '',
      specie3: '',
      specie4: '',
      amount: '',
      amount2: '',
      amount3: '',
      amount4: '',
      counting_method: '',
      counting_method2: '',
      counting_method3: '',
      counting_method4: '',
      behaviour_notes: '',
      recording_number: '',
      observed_at: '',
      photos: undefined,
    },
    values: defaultValues,
    resolver: yupResolver(schema),
  })

  useApiErrorData<ObservationLogFormData>(apiErrorData, methods)

  const deleteSpecie = (number: number) => {
    methods.setValue(`specie${number}` as keyof ObservationLogFormData, '', {
      shouldDirty: true,
    })
    methods.setValue(`amount${number}` as keyof ObservationLogFormData, '', {
      shouldDirty: true,
    })
    methods.setValue(
      `counting_method${number}` as keyof ObservationLogFormData,
      '',
      { shouldDirty: true }
    )
  }

  return (
    <FormProvider {...methods}>
      <Stack mt={4} width="100%" alignItems="stretch" spacing={4}>
        <Stack direction="row" spacing={2} justifyContent="flex-start">
          <Box width="30%">
            <ControlledSelect
              control={methods.control}
              name="specie"
              label={t('observation-log.fieldLabels.specie') + ' 1'}
              options={BATS_SPECIE_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
          <Box width="30%">
            <ControlledTextField
              control={methods.control}
              name="amount"
              placeholder={t('observation-log.fieldLabels.amount') + ' 1'}
              type="number"
              errorMessagePrefix=""
            />
          </Box>
          <Box width="30%">
            <ControlledSelect
              control={methods.control}
              name="counting_method"
              label={t('observation-log.fieldLabels.countingMethod') + ' 1'}
              options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
              errorMessagePrefix=""
            />
          </Box>
        </Stack>
        {methods.watch('specie') && (
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            <Box width="30%">
              <ControlledSelect
                control={methods.control}
                name="specie2"
                label={t('observation-log.fieldLabels.specie2')}
                options={BATS_SPECIE_OPTIONS}
                errorMessagePrefix=""
              />
            </Box>
            {methods.watch('specie2') && (
              <>
                <Box width="30%">
                  <ControlledTextField
                    control={methods.control}
                    name="amount2"
                    placeholder={t('observation-log.fieldLabels.amount2')}
                    type="number"
                    errorMessagePrefix=""
                  />
                </Box>
                <Box width="30%">
                  <ControlledSelect
                    control={methods.control}
                    name="counting_method2"
                    label={t('observation-log.fieldLabels.countingMethod2')}
                    options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
                    errorMessagePrefix=""
                  />
                </Box>
                <Box pt={2}>
                  <IconButton onClick={() => deleteSpecie(2)}>
                    <X size="16px" color={theme.palette.green['500']} />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        )}
        {methods.watch('specie2') && (
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            <Box width="30%">
              <ControlledSelect
                control={methods.control}
                name="specie3"
                label={t('observation-log.fieldLabels.specie3')}
                options={BATS_SPECIE_OPTIONS}
                errorMessagePrefix=""
              />
            </Box>
            {methods.watch('specie3') && (
              <>
                <Box width="30%">
                  <ControlledTextField
                    control={methods.control}
                    name="amount3"
                    placeholder={t('observation-log.fieldLabels.amount3')}
                    type="number"
                    errorMessagePrefix=""
                  />
                </Box>
                <Box width="30%">
                  <ControlledSelect
                    control={methods.control}
                    name="counting_method3"
                    label={t('observation-log.fieldLabels.countingMethod3')}
                    options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
                    errorMessagePrefix=""
                  />
                </Box>
                <Box pt={2}>
                  <IconButton onClick={() => deleteSpecie(3)}>
                    <X size="16px" color={theme.palette.green['500']} />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        )}
        {methods.watch('specie3') && (
          <Stack direction="row" spacing={2} justifyContent="flex-start">
            <Box width="30%">
              <ControlledSelect
                control={methods.control}
                name="specie4"
                label={t('observation-log.fieldLabels.specie4')}
                options={BATS_SPECIE_OPTIONS}
                errorMessagePrefix=""
              />
            </Box>
            {methods.watch('specie4') && (
              <>
                <Box width="30%">
                  <ControlledTextField
                    control={methods.control}
                    name="amount4"
                    placeholder={t('observation-log.fieldLabels.amount4')}
                    type="number"
                    errorMessagePrefix=""
                  />
                </Box>
                <Box width="30%">
                  <ControlledSelect
                    control={methods.control}
                    name="counting_method4"
                    label={t('observation-log.fieldLabels.countingMethod4')}
                    options={BATS_BIRDS_COUNTING_METHOD_OPTIONS}
                    errorMessagePrefix=""
                  />
                </Box>
                <Box pt={2}>
                  <IconButton onClick={() => deleteSpecie(4)}>
                    <X size="16px" color={theme.palette.green['500']} />
                  </IconButton>
                </Box>
              </>
            )}
          </Stack>
        )}
        <ControlledTextField
          control={methods.control}
          name="behaviour_notes"
          placeholder={t('observation-log.fieldLabels.behaviourNotes')}
          multiline={true}
          rows={3}
          inputProps={{
            style: {
              padding: 0,
            },
          }}
          errorMessagePrefix=""
        />
        <Box width="33%">
          <ControlledTextField
            control={methods.control}
            name="recording_number"
            placeholder={t('observation-log.fieldLabels.recordingNumber')}
            type="number"
            errorMessagePrefix=""
          />
        </Box>
        <ControlledDateTimeField
          name="observed_at"
          control={methods.control}
          label={t('observation-log.fieldLabels.observedAt')}
          errorMessagePrefix=""
        />
        <ControlledPhotosUpload
          name="photos"
          control={methods.control}
          label={t('observation-log.fieldLabels.photos')}
          errorMessagePrefix=""
        />

        <ObservationLogFormActions
          onSubmit={onSubmit}
          defaultValues={defaultValues}
          isLoading={isLoading}
          methods={methods}
        />
      </Stack>
    </FormProvider>
  )
}
