import { ModelsWithPhoto } from '@/types'

export const getPhotoBaseEndpoint = (modelKey: ModelsWithPhoto) => {
  if (
    modelKey === ModelsWithPhoto.visitLogs ||
    modelKey === ModelsWithPhoto.observationLogs
  ) {
    return `/plannings/${modelKey}`
  }

  if (modelKey === ModelsWithPhoto.fieldReports) {
    return '/field_reports'
  }

  throw new Error('Invalid model type')
}
